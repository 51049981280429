import axios from "axios";
import React, { useEffect, useState } from "react";

const API_BASE = "https://backenduser.coincurd.com";

const LogRecord = () => {
  const [loginData, setLoginData] = useState([]);

  const id = localStorage.getItem("userId");

  useEffect(() => {
    const getLogData = async () => {
      const response = await axios({
        method: "get",
        url: API_BASE + `/api/log/user/${id}`,
        withCredentials: true,
      });

      let result = response.data.data;

      if (response && result) {
        setLoginData(result);
      }
    };

    getLogData();
  }, [id]);

  return (
    <React.Fragment>
      <div className="table-responsive">
        <table className="table">
          <thead className="thead-light">
            <tr>
              <th>
                <span className="overline-title">Browser</span>
              </th>
              <th>
                <span className="overline-title">Host</span>
              </th>
              <th>
                <span className="overline-title">IP</span>
              </th>
              <th>
                <span className="overline-title">Time</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {loginData.map((log, index) => {
              return (
                <tr key={index}>
                  <td className="tb-col-os">{log.browser}</td>
                  <td className="tb-col-os">{log.host}</td>
                  <td className="tb-col-ip">
                    <span className="sub-text">{log.ip} </span>
                  </td>
                  <td className="tb-col-time">
                    <span className="sub-text">
                      {new Date(log.updatedAt).toLocaleString("en-IN", {
                        hour12: "true",
                      })}
                    </span>
                  </td>
                  {/* <td className="tb-col-ip">
                        <span className="sub-text">
                          {log.status ? "Active" : "Inactive"}
                        </span>
                      </td>
                      <td className="tb-col-action">
                        {log.status === true && (
                          <a
                            href="#delete"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="link-cross mr-sm-n1"
                          ></a>
                        )}
                      </td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export default LogRecord;
