import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Modal, ModalBody, Label, FormGroup, Form } from "reactstrap";
import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Row,
  Col,
  Button,
  Block,
  PreviewCard,
} from "../../components/Component";
import { useForm } from "react-hook-form";
import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

const TicketDetails = () => {
  const [data, setData] = useState([]);
  const [backendError, setBackendError] = useState(null);
  const [backError, setBackError] = useState(null);
  const [backendSuccess, setBackendSuccess] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTicketReply, setSelectedTicketReply] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const userId = localStorage.getItem("userId");

  const onFormSubmit = async (e) => {
    try {
      const response = await axios({
        method: "post",
        url: API_BASE + "/api/ticket",
        data: { ...e },
      });

      if (response) {
        setBackendSuccess("Your response has been successfully submitted!");

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        setBackError(error.response.data.message);
      } else {
        setBackError("Please try again later!");
      }
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedTicketReply(null);
  };

  useEffect(() => {
    const fetchTicketsData = async () => {
      try {
        const response = await axios.get(API_BASE + `/api/getTicket/${userId}`);

        let result = response.data.data;

        if (response && result) {
          setData(result);
        }
      } catch (error) {
        if (error.response) {
          setBackendError(error.response.data.message);
        } else {
          setBackendError("Please try again later!");
        }
      }
    };

    fetchTicketsData();
  }, [userId]);

  const onFormClick = async (ticketId) => {
    try {
      const response = await axios.get(
        API_BASE + `/api/admin/reply/${ticketId}`
      );

      const result = response.data.data;

      if (response && result) {
        const adminReply = result[0].reply;
        setSelectedTicketReply(adminReply);
        setIsModalOpen(true);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Wait for the response!");
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="User" />
      <Content>
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h5">Raise Your Ticket</BlockTitle>
          </BlockHeadContent>
        </BlockHead>
        <Block size="lg">
          <PreviewCard>
            <Form onSubmit={handleSubmit(onFormSubmit)}>
              <Row className="gy-4">
                <Col sm="12">
                  <FormGroup>
                    <Label htmlFor="default-0" className="form-label">
                      Title
                    </Label>
                    <div className="form-control-wrap">
                      <input
                        type="text"
                        id="default-0"
                        placeholder="Title"
                        className="form-control"
                        {...register("title", {
                          required: "This field is required",
                        })}
                      />
                      {errors.title && (
                        <span className="error text-danger">
                          {errors.title.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                </Col>

                <Col sm="12">
                  <FormGroup>
                    <Label htmlFor="default-textarea" className="form-label">
                      Description
                    </Label>
                    <div className="form-control-wrap">
                      <textarea
                        className="no-resize form-control"
                        type="textarea"
                        id="default-textarea"
                        placeholder="Give the description of the issue..."
                        {...register("description", {
                          required: "This field is required",
                        })}
                      />
                      {errors.description && (
                        <span className="error text-danger">
                          {errors.description.message}
                        </span>
                      )}
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <div>
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </div>
                    <div className="text-success font-weight-bold">
                      {backendSuccess}
                    </div>
                  </FormGroup>
                  {backError && (
                    <div className="col-md-12">
                      <span className="error text-danger">{backError}</span>
                    </div>
                  )}
                </Col>
              </Row>
            </Form>
          </PreviewCard>
        </Block>

        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">Raised Tickets</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <div className="card-inner">
              <div className="table-responsive">
                {data && data.length > 0 ? (
                  <table
                    className="table table-borderless"
                    style={{ whiteSpace: "nowrap", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "start" }}>#</th>
                        <th>TrxNo</th>
                        <th>Title</th>
                        <th>Time</th>
                        <th>Status</th>
                        <th>Reply By Admin</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data.map((row, index) => (
                        <tr key={row._id}>
                          <th style={{ textAlign: "start" }}>{index + 1}</th>
                          <td>{row.trxNo}</td>
                          <td>{row.title}</td>

                          <td>{row.time}</td>
                          <td>
                            <span
                              className={`tb-status ml-1 ${
                                row.status === "complete"
                                  ? "text-success"
                                  : row.status === "pending"
                                  ? "text-warning"
                                  : "text-danger"
                              }`}
                            >
                              {row.status}
                            </span>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              disabled={row.status !== "complete"}
                              onClick={() => {
                                onFormClick(row._id);
                              }}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>Nothing to see for now!</div>
                )}
              </div>
              {backendError && (
                <div className="col-md-12">
                  <span className="error text-danger">{backendError}</span>
                </div>
              )}
            </div>

            <Modal isOpen={isModalOpen} toggle={closeModal}>
              <ModalBody>
                {selectedTicketReply && (
                  <div>
                    <h4>Admin Reply</h4>
                    <p>{selectedTicketReply}</p>
                    <Button color="primary" onClick={closeModal}>
                      Back
                    </Button>
                  </div>
                )}
              </ModalBody>
            </Modal>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default TicketDetails;
