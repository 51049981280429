/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";

import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";

import { Form, FormGroup, Spinner, Alert, UncontrolledAlert } from "reactstrap";
import { useForm } from "react-hook-form";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [backendError, setBackendError] = useState(null);
  const [passState, setPassState] = useState(false);

  const onDismiss = () => {
    setError(null);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      let resp = await axios({
        method: "post",
        url: API_BASE + "/api/login",
        data: { ...e },
      });

      if (resp) {
        setSuccess(true);
        localStorage.setItem("token", resp["data"]["token"]);
        localStorage.setItem("userId", resp.data.data._id);
        localStorage.setItem("expiresAt", resp.data.expiresAt);

        setTimeout(() => {
          window.history.pushState(
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`,
            "auth-login",
            `${process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"}`
          );
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="Login" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={coincurdLight}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={coincurdDark}
                alt="logo-dark"
              />
            </Link>
          </div>

          <PreviewCard className="card-bordered this-card-sucks">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Sign-In</BlockTitle>
              </BlockContent>
            </BlockHead>
            {errorVal ? (
              <div className="mb-3">
                <UncontrolledAlert
                  color="danger"
                  toggle={onDismiss}
                  className="alert-icon"
                >
                  <Icon name="alert-circle" /> Unable to login with credentials
                </UncontrolledAlert>
              </div>
            ) : success ? (
              <div className="mb-3">
                <Alert color="success" className="alert-icon">
                  <Icon name="alert-circle" /> Logged In SuccessFully
                </Alert>
              </div>
            ) : null}
            <Form onSubmit={handleSubmit(onFormSubmit)} className="coin-form">
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email Id
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-01"
                    placeholder="Enter your Email Id"
                    className="form-control"
                    {...register("emailId", {
                      required: "This field is required",
                    })}
                  />
                  {errors.emailId && (
                    <span className="error text-danger">
                      {errors.emailId.message}
                    </span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    password
                  </label>
                  <Link
                    className="link link-primary link-sm"
                    to={`${process.env.PUBLIC_URL}/auth-reset`}
                  >
                    Forgot Code?
                  </Link>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                    {...register("password", {
                      required: "This field is required",
                    })}
                  />
                  {errors.password && (
                    <span className="error text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <Button
                  size="lg"
                  className="btn-block"
                  type="submit"
                  color="primary"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
                </Button>
              </FormGroup>
            </Form>
            {backendError && (
              <div className="full-width mt-2">
                <span className="error text-danger fw-bold">
                  {backendError}
                </span>
              </div>
            )}
            <div className="form-note-s2 text-center pt-4">
              New on our platform?&nbsp;
              <Link to={`${process.env.PUBLIC_URL}/auth-register`}>
                Create an account
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Login;
