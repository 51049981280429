import React, { useEffect, useState } from "react";

import Head from "../../../layout/head/Head";

import moment from "moment";
import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

const WalletDetails = () => {
  const [data, setData] = useState([]);
  const [backendError, setBackendError] = useState(null);

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const id = localStorage.getItem("userId");

  useEffect(() => {
    const getRewardTrxHistory = async () => {
      try {
        const response = await axios({
          method: "get",
          url: API_BASE + `/api/wallet/getRewardTrxn/${id}`,
        });

        const result = response.data.data;

        if (response) {
          setData(result);
        }
      } catch (error) {
        if (error.response) {
          setBackendError(error.response.data.message);
        } else {
          setBackendError("Please try again later!");
        }
      }
    };

    getRewardTrxHistory();
  }, []);

  return (
    <React.Fragment>
      <Head title="User" />

      <div className="card-inner card">
        <div className="table-responsive">
          {data ? (
            <table
              className="table table-borderless"
              style={{ whiteSpace: "nowrap", textAlign: "center" }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "start" }}>#TrxId</th>
                  <th>Name</th>
                  <th>Reward</th>
                  <th>Description</th>
                  <th>Time</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((row, index) => (
                    <tr key={index}>
                      <th style={{ textAlign: "start" }}>
                        {row.transactionId}
                      </th>
                      <td>{row.userId && row.userId.fullName}</td>
                      <td>{row.credit}</td>
                      <td>{row.description}</td>
                      <td>{moment(row.createdAt).format("DD/MM/YY hh:ss")}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <div>No Documents</div>
          )}
        </div>
        {backendError && (
          <div className="col-md-12">
            <span className="error text-danger">{backendError}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default WalletDetails;
