import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FormGroup, Label, Col, Row, Container, Form } from "reactstrap";

import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

import { BlockTitle, PreviewCard } from "../../../components/Component";

const API_BASE = "https://backenduser.coincurd.com";

const NewExchange = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [developerApiLink, setDeveloperApiLink] = useState([{ link: "" }]);

  const handleAddLink = (setState, initialState) => {
    setState([...initialState, { link: "" }]);
  };

  const handleRemoveLink = (index, setState, initialState) => {
    const newLinks = [...initialState];
    newLinks.splice(index, 1);
    setState(newLinks);
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormSubmit = async (e) => {
    try {
      const response = await axios({
        method: "post",
        url: API_BASE + "/api/add/exchange",
        data: {
          ...e,
          developerApiLink: developerApiLink.map((linkObj) => linkObj.link),
        },
      });

      if (response) {
        setIsSubmitted(true);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  if (isSubmitted) {
    return (
      <React.Fragment>
        <Content>
          <Container>
            <Row>
              <div className="full-width" style={{ textAlign: "center" }}>
                <h3>Exchange Details submitted successfully!</h3>
                <a href="/" className="mb-3 btn btn-primary">
                  HomePage
                </a>
              </div>
            </Row>
          </Container>
        </Content>
      </React.Fragment>
    );
  }

  return (
    <div className="pt-5">
      <Head title="Add Exchange" />
      <PreviewCard className="pt-4">
        <BlockTitle tag="h5">Add Exchange</BlockTitle>
        <Form onSubmit={handleSubmit(onFormSubmit)} className="pt-4">
          <Row className="gy-4">
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Exchange Name
                </Label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-0"
                    placeholder="Exchange Name"
                    className="form-control"
                    {...register("exchangeName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.exchangeName && (
                    <span className="error text-danger">
                      {errors.exchangeName.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Exchange ID
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="default-0"
                    placeholder="Exchange Id"
                    {...register("exchangeId", {
                      required: "This field is required",
                    })}
                  />
                  {errors.exchangeId && (
                    <span className="error text-danger">
                      {errors.exchangeId.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-1" className="form-label">
                  Spot Trading Volume
                </Label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Usd</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Spot Trading Volume"
                    id="default-1"
                    {...register("volumeUsd", {
                      required: "This field is required",
                    })}
                  />
                  {errors.volumeUsd && (
                    <span className="error text-danger">
                      {errors.volumeUsd.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-1" className="form-label">
                  Avg Liquidity
                </Label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Usd</span>
                  </div>
                  <input
                    className="form-control"
                    type="text"
                    id="default-1"
                    placeholder="Average Liquidity"
                    {...register("AvgLiquidity", {
                      required: "This field is required",
                    })}
                  />
                  {errors.AvgLiquidity && (
                    <span className="error text-danger">
                      {errors.AvgLiquidity.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Issue Date
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="date"
                    id="default-0"
                    placeholder="Date Of Issue"
                    {...register("issueDate", {
                      required: "This field is required",
                    })}
                  />
                  {errors.issueDate && (
                    <span className="error text-danger">
                      {errors.issueDate.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="type" className="form-label">
                  Type
                </Label>
                <div className="form-control-wrap">
                  <div className="form-control-select">
                    <select
                      className="form-control"
                      id="type"
                      {...register("type", {
                        required: "This field is required",
                      })}
                    >
                      <option value="">Select Type</option>
                      <option value="CEX">CEX</option>
                      <option value="DEX">DEX</option>
                    </select>
                  </div>
                  {errors.type && (
                    <span className="error text-danger">
                      {errors.type.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Total Assets
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="number"
                    id="default-0"
                    placeholder="Total Assets"
                    {...register("totalAssets", {
                      required: "This field is required",
                    })}
                  />
                  {errors.totalAssets && (
                    <span className="error text-danger">
                      {errors.totalAssets.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Total Trading Pairs
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="number"
                    placeholder="Total Trading Pairs"
                    id="default-0"
                    {...register("tradingPairs", {
                      required: "This field is required",
                    })}
                  />
                  {errors.tradingPairs && (
                    <span className="error text-danger">
                      {errors.tradingPairs.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Weekly Visit
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="default-0"
                    placeholder="Weekly Visit"
                    {...register("weeklyVisit", {
                      required: "This field is required",
                    })}
                  />
                  {errors.weeklyVisit && (
                    <span className="error text-danger">
                      {errors.weeklyVisit.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            {/* <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Developer Api Link
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="default-0"
                    placeholder="Developer Api Link"
                    {...register("developerApiLink", {
                      required: "This field is required",
                    })}
                  />
                  {errors.developerApiLink && (
                    <span className="error text-danger">
                      {errors.developerApiLink.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col> */}
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Developer Api Links
                </Label>
                <div className="form-control-wrap">
                  {developerApiLink.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-12 pb-1">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Developer Link"
                            value={linkObj.link}
                            onChange={(e) => {
                              const newLinks = [...developerApiLink];
                              newLinks[index].link = e.target.value;
                              setDeveloperApiLink(newLinks);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setDeveloperApiLink,
                                    developerApiLink
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.developerApiLink?.[index] && (
                          <span className="error text-danger">
                            {errors.developerApiLink[index].message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setDeveloperApiLink, developerApiLink)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Social Links
                </Label>
                <div className="form-control-wrap">
                  <div className="form-row">
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="website"
                        placeholder="Website"
                        {...register("socialLinks.Website", {
                          required: "This field is required",
                        })}
                      />
                      {errors.socialLinks?.Website && (
                        <span className="error text-danger">
                          {errors.socialLinks.Website.message}
                        </span>
                      )}
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="facebook"
                        placeholder="Facebook"
                        {...register("socialLinks.Facebook")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="twitter"
                        placeholder="Twitter"
                        {...register("socialLinks.Twitter")}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    {/* Add the input fields for the next three social media platforms here */}
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="gitHub"
                        placeholder="GitHub"
                        {...register("socialLinks.GitHub")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="discord"
                        placeholder="Discord"
                        {...register("socialLinks.Discord")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="reddit"
                        placeholder="Reddit"
                        {...register("socialLinks.Reddit")}
                      />
                    </div>
                  </div>
                  <div className="form-row">
                    {/* Add the input fields for the next three social media platforms here */}
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="explorer"
                        placeholder="Explorer"
                        {...register("socialLinks.Explorer")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="telegram"
                        placeholder="Telegram"
                        {...register("socialLinks.Telegram")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="message_Board"
                        placeholder="Message_Board"
                        {...register("socialLinks.Message_Board")}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label htmlFor="default-textarea" className="form-label">
                  Exchange Description
                </Label>
                <div className="form-control-wrap">
                  <textarea
                    className="no-resize form-control"
                    type="textarea"
                    id="default-textarea"
                    //   defaultValue="Give the description of token..."
                    placeholder="Give the description of Exchange..."
                    {...register("exchangeDescription", {
                      required: "This field is required",
                    })}
                  />
                  {errors.exchangeDescription && (
                    <span className="error text-danger">
                      {errors.exchangeDescription.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <div>
                  <button type="submit" className="mb-3 btn btn-primary">
                    Submit
                  </button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </PreviewCard>
    </div>
  );
};

export default NewExchange;
