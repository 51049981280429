import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import { FormGroup, Label, Col, Row, Container, Form } from "reactstrap";
import axios from "axios";
import { BlockTitle, PreviewCard } from "../../../components/Component";

const API_BASE = "https://backenduser.coincurd.com";

const UploadAdsImages = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [backendError, setBackendError] = useState(null);

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const onFormSubmit = async (e) => {
    try {
      const formData = new FormData();

      formData.append("title", e.title);

      formData.append("imgAd1", e.imgAd1[0]);
      formData.append("imgAd2", e.imgAd2[0]);
      formData.append("imgAd3", e.imgAd3[0]);
      formData.append("imgAd4", e.imgAd4[0]);

      formData.append("description", e.description);

      let response = await axios.post(API_BASE + "/api/upload/ads", formData);

      if (response) {
        setIsSubmitted(true);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  if (isSubmitted) {
    return (
      <React.Fragment>
        <Content>
          <Container>
            <Row>
              <div className="full-width" style={{ textAlign: "center" }}>
                <h3>Asset Details submitted successfully!</h3>
                <a href="/" className="mb-3 btn btn-primary">
                  HomePage
                </a>
              </div>
            </Row>
          </Container>
        </Content>
      </React.Fragment>
    );
  }

  return (
    <div className="pt-5">
      <Head title="List Add" />
      <PreviewCard className="pt-4">
        <BlockTitle tag="h5">List Ads</BlockTitle>
        <Form onSubmit={onFormSubmit} className="pt-3">
          <Row className="gy-4">
            <Col sm="6">
              <FormGroup>
                <Label for="title" className="form-label">
                  Title
                </Label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Title of ads"
                    className="form-control"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="title" className="form-label">
                  Ad/Page Link
                </Label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    name="adLink"
                    id="adLink"
                    placeholder="Link of Ad"
                    className="form-control"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="description" className="form-label">
                  Description
                </Label>
                <div className="form-control-wrap">
                  <textarea
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder="Write Description of ads"
                    className="no-resize form-control"
                  />
                </div>
              </FormGroup>
            </Col>
            <Col sm="6">
              <FormGroup>
                <Label for="img" className="form-label">
                  Upload
                </Label>

                <div className="form-control-wrap">
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <input
                        type="file"
                        name="imgAd1"
                        id="imgAd1"
                        className="form-control"
                      />
                    </div>

                    <div className="form-group col-md-6">
                      <input
                        type="file"
                        name="imgAd2"
                        id="imgAd2"
                        className="form-control"
                      />
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <input
                          type="file"
                          name="imgAd3"
                          id="imgAd3"
                          className="form-control"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <input
                          type="file"
                          name="imgAd4"
                          id="imgAd4"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <div>
                  <button type="submit" className="mb-3 btn btn-primary">
                    Upload Images
                  </button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        {backendError && (
          <div className="col-md-12">
            <span className="error text-danger">{backendError}</span>
          </div>
        )}
      </PreviewCard>
    </div>
  );
};
export default UploadAdsImages;
