/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Row } from "../../components/Component";
import axios from "axios";

import "./dashboard.css";

const API_BASE = "https://backenduser.coincurd.com";

const Homepage = () => {
  const [data, setData] = useState([]);
  const [backendError, setBackendError] = useState(null);
  const [openItems, setOpenItems] = useState([]);
  const [inProgressItems, setInProgressItems] = useState([]);
  const [toReviewItems, setToReviewItems] = useState([]);
  const [completedItems, setCompletedItems] = useState([]);

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const id = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          API_BASE + `/api/getUserRequestList/${id}`
        );
        let result = [...response.data.data];

        if (response && result) {
          // Organize the items based on their status
          const open = result.filter((item) => item.status === "Pending");
          const inProgress = result.filter(
            (item) => item.status === "Progress"
          );
          const toReview = result.filter((item) => item.status === "Active");
          const completed = result.filter(
            (item) => item.status === "Completed"
          );

          // Set the organized items and their counts
          setOpenItems(open);
          setInProgressItems(inProgress);
          setToReviewItems(toReview);
          setCompletedItems(completed);

          setData(result);
        }
      } catch (error) {
        if (error.response) {
          setBackendError(error.response.data.message);
        } else {
          setBackendError("Please try again later!");
        }
      }
    };

    fetchData();
  }, [id]);

  return (
    <React.Fragment>
      <Head title="User" />
      <Content>
        <Row>
          <div className="container-fluid">
            <div className="nk-content-inner">
              <div className="nk-content-body">
                <div className="nk-block-head nk-block-head-sm">
                  <div className="nk-block-between">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title page-title">
                        All Request Services
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="nk-block">
                  <div className="nk-kanban">
                    <div
                      className="kanban-container"
                      id="kanban-container"
                      data-rbd-droppable-id="all-columns"
                      data-rbd-droppable-context-id="0"
                      style={{ width: "1280px" }}
                    >
                      {/* Kanban board for "Open" column */}
                      <div
                        className="kanban-board"
                        data-rbd-draggable-context-id="0"
                        data-rbd-draggable-id="column-open"
                      >
                        <div
                          className="kanban-board-header kanban-light"
                          tabIndex="0"
                          role="button"
                          aria-describedby="rbd-hidden-text-0-hidden-text-0"
                          data-rbd-drag-handle-draggable-id="column-open"
                          data-rbd-drag-handle-context-id="0"
                          draggable="false"
                        >
                          <div className="kanban-title-board">
                            <div className="kanban-title-content">
                              <h6 className="title">Open</h6>
                              <span className="text-dark badge bg-outline-light rounded-pill">
                                {openItems.length}
                              </span>
                            </div>
                            <div className="kanban-title-content">
                              <div className="dropdown">
                                {/* Dropdown content for "Open" column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="kanban-drag"
                          data-rbd-droppable-id="column-open"
                          data-rbd-droppable-context-id="0"
                        >
                          {/* Render the items in "Open" */}
                          {openItems.map((item) => (
                            <div
                              key={item._id}
                              data-rbd-draggable-context-id="0"
                              data-rbd-draggable-id={`task-${item._id}`}
                              tabIndex="0"
                              role="button"
                              aria-describedby="rbd-hidden-text-0-hidden-text-0"
                              data-rbd-drag-handle-draggable-id={`task-${item._id}`}
                              data-rbd-drag-handle-context-id="0"
                              draggable="false"
                              className="mt-2"
                            >
                              {/* ... (rest of the Kanban item JSX) */}
                              {/* Example: */}
                              {/* <div className="kanban-item">
                                    <div className="kanban-item-title">
                                      <h6 className="title">{item.title}</h6>
                                    </div>
                                    <div className="kanban-item-text">
                                      <p>{item.description}</p>
                                    </div>
                                    {/* ... (other item details) */}
                              {/* </div> */}
                              <div className="kanban-item">
                                <div className="kanban-item-title">
                                  <h6 className="title">{item.TrxNo}</h6>
                                </div>
                                <div className="kanban-item-text">
                                  <p>{item.AssetType}</p>
                                </div>
                                <div className="kanban-item-meta">
                                  <ul className="kanban-item-meta-list">
                                    <li>
                                      <em className="icon ni ni-calendar"></em>
                                      <span>
                                        {new Date(
                                          item.createdAt
                                        ).toLocaleDateString("en-IN")}
                                      </span>
                                    </li>
                                    <li>
                                      <em className="icon ni ni-notes"></em>
                                      <span>{item.status}</span>
                                    </li>
                                  </ul>
                                  <ul className="kanban-item-meta-list">
                                    <div className="dropdown">
                                      <a
                                        // href="toggle"
                                        // aria-haspopup="true"
                                        className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                                        aria-expanded="false"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        tabindex="-1"
                                        role="menu"
                                        aria-hidden="true"
                                        className="dropdown-menu dropdown-menu-end"
                                      >
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <a
                                              // href="#item"
                                              // tabindex="0"
                                              // role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-edit"></em>
                                              <span>Edit Task</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#item"
                                              tabindex="0"
                                              role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-trash"></em>
                                              <span>Delete Task</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </ul>
                                </div>
                                {/* ... (other item details) */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Kanban board for "In Progress" column */}
                      <div
                        className="kanban-board"
                        data-rbd-draggable-context-id="0"
                        data-rbd-draggable-id="column-progress"
                      >
                        <div
                          className="kanban-board-header kanban-primary"
                          tabIndex="0"
                          role="button"
                          aria-describedby="rbd-hidden-text-0-hidden-text-0"
                          data-rbd-drag-handle-draggable-id="column-progress"
                          data-rbd-drag-handle-context-id="0"
                          draggable="false"
                        >
                          <div className="kanban-title-board">
                            <div className="kanban-title-content">
                              <h6 className="title">In Progress</h6>
                              <span className="text-dark badge bg-outline-light rounded-pill">
                                {inProgressItems.length}
                              </span>
                            </div>
                            <div className="kanban-title-content">
                              <div className="dropdown">
                                {/* Dropdown content for "In Progress" column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="kanban-drag"
                          data-rbd-droppable-id="column-progress"
                          data-rbd-droppable-context-id="0"
                        >
                          {/* Render the items in "In Progress" */}
                          {inProgressItems.map((item) => (
                            <div
                              key={item._id}
                              data-rbd-draggable-context-id="0"
                              data-rbd-draggable-id={`task-${item._id}`}
                              tabIndex="0"
                              role="button"
                              aria-describedby="rbd-hidden-text-0-hidden-text-0"
                              data-rbd-drag-handle-draggable-id={`task-${item._id}`}
                              data-rbd-drag-handle-context-id="0"
                              draggable="false"
                              className="mt-2"
                            >
                              {/* ... (rest of the Kanban item JSX) */}
                              <div className="kanban-item">
                                <div className="kanban-item-title">
                                  <h6 className="title">{item.TrxNo}</h6>
                                </div>
                                <div className="kanban-item-text">
                                  <p>{item.AssetType}</p>
                                </div>
                                <div className="kanban-item-meta">
                                  <ul className="kanban-item-meta-list">
                                    <li>
                                      <em className="icon ni ni-calendar"></em>
                                      <span>
                                        {new Date(
                                          item.updatedAt
                                        ).toLocaleDateString("en-IN")}
                                      </span>
                                    </li>
                                    <li>
                                      <em className="icon ni ni-notes"></em>
                                      <span>{item.status}</span>
                                    </li>
                                  </ul>
                                  <ul className="kanban-item-meta-list">
                                    <div className="dropdown">
                                      <a
                                        // href="toggle"
                                        // aria-haspopup="true"
                                        className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                                        aria-expanded="false"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        tabindex="-1"
                                        role="menu"
                                        aria-hidden="true"
                                        className="dropdown-menu dropdown-menu-end"
                                      >
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <a
                                              // href="#item"
                                              // tabindex="0"
                                              // role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-edit"></em>
                                              <span>Edit Task</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#item"
                                              tabindex="0"
                                              role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-trash"></em>
                                              <span>Delete Task</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </ul>
                                </div>
                                {/* ... (other item details) */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Kanban board for "To Review" column */}
                      <div
                        className="kanban-board"
                        data-rbd-draggable-context-id="0"
                        data-rbd-draggable-id="column-review"
                      >
                        <div
                          className="kanban-board-header kanban-warning"
                          tabIndex="0"
                          role="button"
                          aria-describedby="rbd-hidden-text-0-hidden-text-0"
                          data-rbd-drag-handle-draggable-id="column-review"
                          data-rbd-drag-handle-context-id="0"
                          draggable="false"
                        >
                          <div className="kanban-title-board">
                            <div className="kanban-title-content">
                              <h6 className="title">To Review</h6>
                              <span className="text-dark badge bg-outline-light rounded-pill">
                                {toReviewItems.length}
                              </span>
                            </div>
                            <div className="kanban-title-content">
                              <div className="dropdown">
                                {/* Dropdown content for "To Review" column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="kanban-drag"
                          data-rbd-droppable-id="column-review"
                          data-rbd-droppable-context-id="0"
                        >
                          {/* Render the items in "To Review" */}
                          {toReviewItems.map((item) => (
                            <div
                              key={item._id}
                              data-rbd-draggable-context-id="0"
                              data-rbd-draggable-id={`task-${item._id}`}
                              tabIndex="0"
                              role="button"
                              aria-describedby="rbd-hidden-text-0-hidden-text-0"
                              data-rbd-drag-handle-draggable-id={`task-${item._id}`}
                              data-rbd-drag-handle-context-id="0"
                              draggable="false"
                              className="mt-2"
                            >
                              {/* ... (rest of the Kanban item JSX) */}
                              <div className="kanban-item">
                                <div className="kanban-item-title">
                                  <h6 className="title">{item.TrxNo}</h6>
                                </div>
                                <div className="kanban-item-text">
                                  <p>{item.AssetType}</p>
                                </div>
                                <div className="kanban-item-meta">
                                  <ul className="kanban-item-meta-list">
                                    <li>
                                      <em className="icon ni ni-calendar"></em>
                                      <span>
                                        {new Date(
                                          item.updatedAt
                                        ).toLocaleDateString("en-IN")}
                                      </span>
                                    </li>
                                    <li>
                                      <em className="icon ni ni-notes"></em>
                                      <span>{item.status}</span>
                                    </li>
                                  </ul>
                                  <ul className="kanban-item-meta-list">
                                    <div className="dropdown">
                                      <a
                                        // href="toggle"
                                        // aria-haspopup="true"
                                        className="dropdown-toggle btn btn-xs btn-icon btn-trigger me-n1"
                                        aria-expanded="false"
                                      >
                                        <em className="icon ni ni-more-v"></em>
                                      </a>
                                      <div
                                        tabindex="-1"
                                        role="menu"
                                        aria-hidden="true"
                                        className="dropdown-menu dropdown-menu-end"
                                      >
                                        <ul className="link-list-opt no-bdr">
                                          <li>
                                            <a
                                              // href="#item"
                                              // tabindex="0"
                                              // role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-edit"></em>
                                              <span>Edit Task</span>
                                            </a>
                                          </li>
                                          <li>
                                            <a
                                              href="#item"
                                              tabindex="0"
                                              role="menuitem"
                                              className="dropdown-item"
                                            >
                                              <em className="icon ni ni-trash"></em>
                                              <span>Delete Task</span>
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </ul>
                                </div>
                                {/* ... (other item details) */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Kanban board for "Completed" column */}
                      <div
                        className="kanban-board"
                        data-rbd-draggable-context-id="0"
                        data-rbd-draggable-id="column-completed"
                      >
                        <div
                          className="kanban-board-header kanban-success"
                          tabIndex="0"
                          role="button"
                          aria-describedby="rbd-hidden-text-0-hidden-text-0"
                          data-rbd-drag-handle-draggable-id="column-completed"
                          data-rbd-drag-handle-context-id="0"
                          draggable="false"
                        >
                          <div className="kanban-title-board">
                            <div className="kanban-title-content">
                              <h6 className="title">Completed</h6>
                              <span className="text-dark badge bg-outline-light rounded-pill">
                                {completedItems.length}
                              </span>
                            </div>
                            <div className="kanban-title-content">
                              <div className="dropdown">
                                {/* Dropdown content for "Completed" column */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="kanban-drag"
                          data-rbd-droppable-id="column-completed"
                          data-rbd-droppable-context-id="0"
                        >
                          {/* Render the items in "Completed" */}
                          {completedItems.map((item) => (
                            <div
                              key={item._id}
                              data-rbd-draggable-context-id="0"
                              data-rbd-draggable-id={`task-${item._id}`}
                              tabIndex="0"
                              role="button"
                              aria-describedby="rbd-hidden-text-0-hidden-text-0"
                              data-rbd-drag-handle-draggable-id={`task-${item._id}`}
                              data-rbd-drag-handle-context-id="0"
                              draggable="false"
                              className="mt-2"
                            >
                              {/* ... (rest of the Kanban item JSX) */}
                              <div className="kanban-item">
                                <div className="kanban-item-title">
                                  <h6 className="title">{item.TrxNo}</h6>
                                </div>
                                <div className="kanban-item-text">
                                  <p>{item.AssetType}</p>
                                </div>

                                <div className="kanban-item-meta">
                                  <ul className="kanban-item-meta-list">
                                    <li>
                                      <em className="icon ni ni-calendar"></em>
                                      <span>
                                        {new Date(
                                          item.updatedAt
                                        ).toLocaleDateString("en-IN")}
                                      </span>
                                    </li>
                                    <li>
                                      <em className="icon ni ni-notes"></em>
                                      <span>{item.status}</span>
                                    </li>
                                  </ul>
                                </div>

                                {/* ... (other item details) */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
        {backendError && (
          <div className="col-md-12">
            <span className="error text-danger">{backendError}</span>
          </div>
        )}
      </Content>
    </React.Fragment>
  );
};

export default Homepage;
