import React, { useState, useEffect } from "react";
import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  // Icon,
  PreviewCard,
} from "../../components/Component";
import { Form, FormGroup, Spinner } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";

const API_BASE = "https://backenduser.coincurd.com";

const Register = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [backendError, setBackendError] = useState(null);
  const [passState, setPassState] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onFormSubmit = async (e) => {
    try {
      setLoading(true);
      let resp = await axios({
        method: "post",
        url: API_BASE + `/api/signup`,
        data: {
          ...e,
        },
      });

      if (resp.data) {
        alert("successfully registed, Proceed to login!");
        window.location.replace("/auth-success");
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referralCode");
    setValue("referralCode", referralCode);
  }, [location, setValue]);

  return (
    <React.Fragment>
      <Head title="Register" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={coincurdLight}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={coincurdDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard
            className="card-bordered this-card-sucks"
            bodyClass="card-inner-lg"
          >
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4">Register</BlockTitle>
                <BlockDes>
                  <p>Create New Coincurd Account</p>
                </BlockDes>
              </BlockContent>
            </BlockHead>

            <Form onSubmit={handleSubmit(onFormSubmit)} className="coin-form">
              <FormGroup>
                <label className="form-label" htmlFor="fullName">
                  Full Name
                </label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="fullName"
                    placeholder="Enter your name"
                    className="form-control"
                    {...register("fullName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.fullName && (
                    <span className="error text-danger">
                      {errors.fullName.message}
                    </span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    User Name
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    name="userName"
                    placeholder="Enter your username"
                    className="form-control"
                    {...register("userName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.userName && (
                    <span className="error text-danger">
                      {errors.userName.message}
                    </span>
                  )}
                </div>
              </FormGroup>

              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email Id
                  </label>
                </div>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    placeholder="Enter your Email Id"
                    className="form-control"
                    {...register("emailId", {
                      required: "This field is required",
                    })}
                  />
                  {errors.emailId && (
                    <span className="error text-danger">
                      {errors.emailId.message}
                    </span>
                  )}
                </div>
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon
                      name="eye-off"
                      className="passcode-icon icon-hide"
                    ></Icon>
                  </a>
                  <input
                    type={passState ? "text" : "password"}
                    id="password"
                    placeholder="Enter your password"
                    className={`form-control-lg form-control ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                    {...register("password", {
                      required: "This field is required",
                    })}
                  />
                  {errors.password && (
                    <span className="error text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </div>
              </FormGroup>

              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="referralCode">
                    Referral Code
                  </label>
                </div>

                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="referralCode"
                    placeholder="Referral Code"
                    className="form-control"
                    {...register("referralCode")}
                  />

                  {errors.referralCode && (
                    <span className="error text-danger">
                      {errors.referralCode.message}
                    </span>
                  )}
                </div>
              </FormGroup>

              <FormGroup>
                <Button
                  type="submit"
                  color="primary"
                  size="sm"
                  className="btn-block"
                >
                  {loading ? <Spinner size="sm" color="light" /> : "Register"}
                </Button>
              </FormGroup>
            </Form>
            {backendError && (
              <div className="full-width mt-2">
                <span className="error text-danger fw-bold">
                  {backendError}
                </span>
              </div>
            )}

            <div className="form-note-s2 text-center pt-4">
              Already have an account?{" "}
              <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
                <strong>Sign in instead</strong>
              </Link>
            </div>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Register;
