import React, { useState } from "react";
import UserAvatar from "../../../../components/user/UserAvatar";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useEffect } from "react";
import axios from "axios";
import { findUpper } from "../../../../utils/Utils";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import { cctWalletState, rewardWalletState, userState } from "../../../../atom";

const API_BASE = "https://backenduser.coincurd.com";

const User = () => {
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useRecoilState(userState);

  const [rewardWalletData, setRewardWalletData] =
    useRecoilState(rewardWalletState);
  const [cctWalletData, setCctWalletData] = useRecoilState(cctWalletState);

  const toggle = () => setOpen((prevState) => !prevState);

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const id = localStorage.getItem("userId");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios({
          method: "get",
          url: API_BASE + `/api/user/${id}`,
          withCredentials: true,
        });

        const result = response.data;

        if (response.status === 200 && result.status) {
          setUserInfo(result.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [id]);

  const handleSignout = () => {
    localStorage.clear();
  };

  return (
    <>
      <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
        <DropdownToggle
          tag="a"
          href="#toggle"
          className="dropdown-toggle "
          onClick={(ev) => {
            ev.preventDefault();
          }}
        >
          <div className="user-toggle ">
            <UserAvatar
              text={userInfo && findUpper(userInfo.fullName)}
              className="sm "
            />
            <div className="user-info d-none d-md-block ">
              <div className="user-status ">
                {" "}
                {userInfo && userInfo.fullName}
              </div>
              <div className="user-name dropdown-indicator">
                {userInfo && userInfo.emailId}
              </div>
            </div>
          </div>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-md dropdown-menu-s1">
          <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
            <div className="user-card">
              <UserAvatar
                text={userInfo && findUpper(userInfo && userInfo.fullName)}
                className="sm "
              />
              <div className="user-info">
                <span className="lead-text">
                  {userInfo && userInfo.fullName}
                </span>
                <span className="sub-text">{userInfo && userInfo.emailId}</span>
              </div>
              <div className="user-action">
                <a className="btn btn-icon me-n2" href="/user-profile-setting">
                  <em className="icon ni ni-setting"></em>
                </a>
              </div>
            </div>
          </div>

          <div className="dropdown-inner user-account-info">
            <h6 className="overline-title-alt">Account Balance</h6>
            <Link to="/wallet">
              <small className="user-balance">
                {rewardWalletData &&
                  cctWalletData &&
                  Number(rewardWalletData.balance) +
                    Number(cctWalletData.balance)}{" "}
                CCT
              </small>
            </Link>
          </div>
          <div className="dropdown-inner">
            <LinkList>
              <LinkItem link="/user-profile" icon="user-alt" onClick={toggle}>
                View Profile
              </LinkItem>
              <LinkItem
                link="/user-profile-setting"
                icon="setting-alt"
                onClick={toggle}
              >
                Account Setting
              </LinkItem>
              <LinkItem
                link="/user-profile-activity"
                icon="activity-alt"
                onClick={toggle}
              >
                Login Activity
              </LinkItem>
            </LinkList>
          </div>
          <div className="dropdown-inner">
            <LinkList>
              <a
                href={`${process.env.PUBLIC_URL}/auth-login`}
                onClick={handleSignout}
              >
                <Icon name="signout"></Icon>
                <span>Sign Out</span>
              </a>
            </LinkList>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default User;
