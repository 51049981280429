import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  // Row,
  // Col,
  // Button,
  Block,
  PreviewCard,
} from "../../../components/Component";

import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

const ApiListTable = () => {
  const [backendError, setBackendError] = useState(null);
  const [paidApiData, setPaidApiData] = useState([]);
  const [freeApiData, setFreeApiData] = useState([]);
  const [copiedRowIndex, setCopiedRowIndex] = useState(null);

  useEffect(() => {
    fetchApiData();
  }, []);

  const fetchApiData = async () => {
    try {
      const response = await axios.get({
        method: "get",
        url: API_BASE + "/api/get/paidapikey",
      });

      if (response && response.data) {
        const paidKeys = response.data.data.paidApiKey;
        const freeKeys = response.data.data.freeApiKey;
        setPaidApiData(paidKeys);
        setFreeApiData(freeKeys);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  const copyToClipboard = async (apiKey, rowIndex) => {
    try {
      await navigator.clipboard.writeText(apiKey);
      setCopiedRowIndex(rowIndex);
      setTimeout(() => {
        setCopiedRowIndex(null);
      }, 1000);
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  return (
    <React.Fragment>
      <Head title="Api" />
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <BlockTitle tag="h5">All your Api</BlockTitle>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <div className="card-inner">
              <div className="table-responsive">
                {paidApiData.length > 0 || freeApiData.length > 0 ? (
                  <table
                    className="table table-borderless"
                    style={{ whiteSpace: "nowrap", textAlign: "center" }}
                  >
                    <thead>
                      <tr>
                        <th style={{ textAlign: "start" }}>#</th>
                        <th>Plan</th>
                        <th>Price</th>
                        <th>Api Key</th>
                        <th>Time</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {freeApiData.concat(paidApiData).map((row, index) => (
                        <tr key={row._id}>
                          <th style={{ textAlign: "start" }}>{index + 1}</th>
                          <td>{row.plan ? row.plan : "Demo"}</td>
                          <td>${row.price ? row.price : "0"}</td>
                          <td>
                            <span
                              style={{
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                copyToClipboard(row.Api_Key, index)
                              }
                            >
                              {row.Api_Key ? row.Api_Key : "unavailable"}{" "}
                              {copiedRowIndex === index ? (
                                <span style={{ color: "#007bff" }}>✓</span>
                              ) : (
                                <span style={{ color: "#007bff" }}>
                                  <i className="bi bi-files"></i>
                                </span>
                              )}
                            </span>
                          </td>
                          <td>
                            {new Date(row.createdAt).toLocaleString("en-IN", {
                              dateStyle: "full",
                              timeStyle: "short",
                            })}
                          </td>
                          <td>
                            <span
                              className={`tb-status ml-1 ${
                                row.status ? "text-success" : "text-warning"
                              }`}
                            >
                              {row.status ? "Active" : "Inactive"}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div>Nothing to see for now!</div>
                )}
              </div>
              {backendError && (
                <div className="col-md-12">
                  <span className="error text-danger">{backendError}</span>
                </div>
              )}
            </div>
          </PreviewCard>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ApiListTable;
