import React, { useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import WalletDetails from "./walletTable";

import {
  Button,
  Col,
  PreviewAltCard,
  Row,
  TooltipComponent,
} from "../../../components/Component";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import { cctWalletState, rewardWalletState } from "../../../atom";

const WalletDashboard = () => {
  const [showWalletDetails, setShowWalletDetails] = useState(false);
  const [rewardWalletData, setRewardWalletData] =
    useRecoilState(rewardWalletState);
  const [cctWalletData, setCctWalletData] = useRecoilState(cctWalletState);

  return (
    <React.Fragment>
      <Head title="Transaction" />
      <Content>
        <div className="nk-block nk-block-lg">
          <h4 className="nk-block-title title">All Wallets</h4>

          <Row className="g-gs pb-5 pt-2">
            <Col md="4">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">
                      <strong>Reward Wallet</strong>
                    </h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="reward-wallet"
                      text="Total CCT earned"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <Link className="nk-menu-link">
                    <span className="amount">
                      {rewardWalletData && rewardWalletData.balance}
                      <span className="currency currency-usd p-1">CCT</span>
                    </span>
                  </Link>
                </div>
                <span className="d-flex justify-content-space-around">
                  <Button>Buy Reward</Button>
                  <Button
                    onClick={() => setShowWalletDetails(!showWalletDetails)}
                  >
                    Transaction
                  </Button>
                </span>
              </PreviewAltCard>
            </Col>
            <Col md="4">
              <PreviewAltCard className="card-full card-bordered">
                <div className="card-title-group align-start mb-0">
                  <div className="card-title">
                    <h6 className="subtitle">
                      <strong>CCT Wallet</strong>
                    </h6>
                  </div>
                  <div className="card-tools">
                    <TooltipComponent
                      iconClass="card-hint"
                      icon="help-fill"
                      direction="left"
                      id="invest-deposit"
                      text="Total CCT deposited"
                    ></TooltipComponent>
                  </div>
                </div>
                <div className="card-amount">
                  <Link className="nk-menu-link">
                    <span className="amount">
                      {cctWalletData && cctWalletData.balance}
                      <span className="currency currency-usd p-1">CCT</span>
                    </span>
                  </Link>
                </div>
                <span className="d-flex justify-content-space-around">
                  <Button>Add Fund</Button>
                  <Button>Transaction</Button>
                </span>
              </PreviewAltCard>
            </Col>
          </Row>

          {showWalletDetails && <WalletDetails />}
        </div>
      </Content>
    </React.Fragment>
  );
};

export default WalletDashboard;
