/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from "react";
import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../components/Component";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

const ResetPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");

  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let { token } = useParams();

  const handleNewPasswordChange = (e) => {
    setPassword(e.target.value);
    setErrorMessage("");
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorMessage("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (password !== confirmPassword) {
        setErrorMessage("New Password and Confirm Password do not match!");
      } else {
        const response = await axios.post(
          API_BASE + `/api/resetEmailPassword`,
          {
            password: password,
            confirmPassword: confirmPassword,
            token: token,
          }
        );

        setMessage(response.data.message);
        setIsSubmitted(true);
      }
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  // if (isSubmitted) {
  //   return (
  //     <Fragment>
  //       <div>
  //         <div className="text-center">
  //           <Form>
  //             <br />
  //             <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
  //               <img
  //                 className="logo-light logo-img logo-img-lg"
  //                 src={coincurdLight}
  //                 alt="logo"
  //               />
  //               <img
  //                 className="logo-dark logo-img logo-img-lg"
  //                 src={coincurdDark}
  //                 alt="logo-dark"
  //               />
  //             </Link>
  //             <br /> <br />
  //             <h4>Password is successfully reset!</h4>
  //             <a href="/auth-login" className="btn btn-dark">
  //               login
  //             </a>
  //           </Form>
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
  // }

  if (isSubmitted) {
    return (
      <React.Fragment>
        <Head title="Success" />
        <PageContainer>
          <div className="nk-content">
            <div className="nk-block nk-block-middle nk-auth-body">
              <div className="brand-logo pb-5">
                <a className="logo-link" href="/demo8/">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={coincurdLight}
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={coincurdDark}
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="nk-block-head">
                <div className="nk-block-content">
                  <h4 className="nk-block-title title">
                    Thank you for submitting form
                  </h4>
                  <div className="nk-block-des text-success">
                    <p>Password is successfully reset!</p>
                    <a href="/auth-login">
                      <Button
                        type="submit"
                        color="primary"
                        size="sm"
                        className="btn-block"
                      >
                        Back to Login
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AuthFooter />
        </PageContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={coincurdLight}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={coincurdDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
              </BlockContent>
            </BlockHead>
            <Form onSubmit={handleSubmit}>
              <FormGroup>
                <div className="form-label-group">
                  <Label className="form-label" htmlFor="default-01">
                    Enter Your New Password
                  </Label>
                </div>
                <Input
                  className="form-control form-control-lg"
                  type="password"
                  value={password}
                  onChange={handleNewPasswordChange}
                  required
                  placeholder="Enter your password"
                />
              </FormGroup>
              <FormGroup>
                <div className="form-label-group">
                  <Label className="form-label" htmlFor="default-01">
                    Confirm Your New Password
                  </Label>
                </div>
                <Input
                  className="form-control form-control-lg"
                  type="password"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  required
                  placeholder="Re-Enter your password"
                />
              </FormGroup>
              <FormGroup>
                <Input
                  className="form-control form-control-lg"
                  type="hidden"
                  value={token}
                />
                <Button
                  color="primary"
                  size="sm"
                  className="btn-block"
                  type="submit"
                >
                  submit
                </Button>
              </FormGroup>
              {message && <p>{message}</p>}
              {errorMessage && <p className="  text-danger">{errorMessage}</p>}
              <p>
                Already have an password?&nbsp;
                <a className="ms-2" href="/login">
                  Sign in
                </a>
              </p>
            </Form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ResetPassword;
