import React from "react";
import { Route, Redirect } from "react-router-dom";

const auth = localStorage.getItem("token");
const userId = localStorage.getItem("userId");
const expiresAt = localStorage.getItem("expiresAt");

const isTokenExpired = () => {
  if (expiresAt) {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > parseInt(expiresAt, 10);
  }
  return true; // Token is considered expired if not found or invalid
};

const PrivateRoute = ({ exact, component: Component, ...rest }) => (
  <Route
    exact={exact}
    render={(props) =>
      auth && userId && !isTokenExpired() ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to="/auth-login" />
      )
    }
  />
);

export default PrivateRoute;
