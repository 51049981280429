import React, { useState } from "react";
import Head from "../../../layout/head/Head";
import { Modal, ModalBody, FormGroup, Form } from "reactstrap";
import axios from "axios";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Row,
  Col,
  Button,
} from "../../../components/Component";

import { useForm } from "react-hook-form";
import { useRecoilState } from "recoil";
import { userState } from "../../../atom";
const API_BASE = "https://backenduser.coincurd.com";

const UserProfileRegularPage = ({ sm, updateSm }) => {
  const [modalTab, setModalTab] = useState("1");

  const [backendError, setBackendError] = useState(null);
  const [userData, setUserData] = useRecoilState(userState);
  const [modal, setModal] = useState(false);
  const [copied, setCopied] = useState(false);

  const { register, handleSubmit } = useForm();
  const id = localStorage.getItem("userId");

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const onFormSubmit = async (e) => {
    try {
      const response = await axios({
        method: "put",
        url: API_BASE + `/api/update/${id}`,
        withCredentials: true,
        data: { ...e },
      });

      if (response) {
        setBackendError(null);
        setModal(false);
        window.location.reload();
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(userData.referralLink);
    setCopied(true);
  };

  return (
    <React.Fragment>
      <Head title="User List - Profile"></Head>
      <BlockHead size="lg">
        <BlockBetween>
          <BlockHeadContent>
            <BlockTitle tag="h4">Personal Information</BlockTitle>
            <BlockDes>
              <p>
                Basic info, like your name and address, that you use on Coincurd
                Platform.
              </p>
            </BlockDes>
          </BlockHeadContent>
          <BlockHeadContent className="align-self-start d-lg-none">
            <Button
              size="sm"
              className={`toggle btn btn-icon btn-trigger mt-n1 ${
                sm ? "active" : ""
              }`}
              onClick={() => updateSm(!sm)}
            >
              <Icon name="menu-alt-r"></Icon>
            </Button>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <Block>
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Full Name</span>
              <span className="data-value">
                {userData && userData.fullName}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">User Name</span>
              <span className="data-value">
                {userData && userData.userName}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Email</span>
              <span className="data-value">{userData && userData.emailId}</span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Referral Code</span>
              <span className="data-value">
                {userData && userData.referralCode}
              </span>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
          <div className="data-item">
            <div className="data-col">
              <span className="data-label">Referral Link</span>
              <Button
                color="primary"
                size="sm"
                onClick={handleCopy}
                style={{ cursor: "pointer" }}
              >
                {copied ? "Link Copied !" : "Copy Link"}
              </Button>
            </div>
            <div className="data-col data-col-end"></div>
          </div>
        </div>

        <Button color="primary" size="sm" onClick={() => setModal(true)}>
          Update Profile
        </Button>
      </Block>

      <Modal
        isOpen={modal}
        className="modal-dialog-centered"
        size="lg"
        toggle={() => setModal(false)}
      >
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Update Profile</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Personal
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div
                className={`tab-pane ${modalTab === "1" ? "active" : ""}`}
                id="personal"
              >
                <Form onSubmit={handleSubmit(onFormSubmit)}>
                  <Row className="gy-4">
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="fullName">
                          Full Name
                        </label>
                        <input
                          type="text"
                          id="fullName"
                          className="form-control"
                          {...register("fullName")}
                          placeholder="Enter Full Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="userName">
                          User Name
                        </label>
                        <input
                          type="text"
                          id="userName"
                          className="form-control"
                          {...register("userName")}
                          placeholder="Enter User Name"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label className="form-label" htmlFor="emailId">
                          Email Address
                        </label>
                        <input
                          type="text"
                          id="emailId"
                          className="form-control"
                          {...register("emailId")}
                          placeholder="email Id"
                        />
                      </FormGroup>
                    </Col>

                    <Col size="12">
                      <div className="custom-control custom-switch">
                        <input
                          type="checkbox"
                          className="custom-control-input form-control"
                          id="latest-sale"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="latest-sale"
                        >
                          Use full name to display
                        </label>
                      </div>
                    </Col>
                    <Col size="12">
                      <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                        <li>
                          <Button color="primary" size="sm">
                            Update Profile
                          </Button>
                        </li>
                        <li>
                          <a
                            href="#dropdownitem"
                            onClick={(ev) => {
                              ev.preventDefault();
                              setModal(false);
                            }}
                            className="link link-light"
                          >
                            Cancel
                          </a>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                  {backendError && (
                    <div className="col-md-12">
                      <span className="error text-danger">{backendError}</span>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default UserProfileRegularPage;
