import React from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import Logo from "../logo/Logo";
import User from "./dropdown/user/User";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useRecoilState } from "recoil";
import { cctWalletState, rewardWalletState, userState } from "../../atom";

const Header = ({ fixed, theme, className, setVisibility, ...props }) => {
  const [userData, setUserData] = useRecoilState(userState);
  const [rewardWalletData, setRewardWalletData] =
    useRecoilState(rewardWalletState);
  const [cctWalletData, setCctWalletData] = useRecoilState(cctWalletState);

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme === "white",
    [`is-${theme}`]: theme !== "white" && theme !== "light",
    [`${className}`]: className,
  });

  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <Toggle
              className="nk-nav-toggle nk-quick-nav-icon d-xl-none ml-n1"
              icon="menu"
              click={props.sidebarToggle}
            />
          </div>
          <div className="nk-header-app-name">
            <div className="nk-header-app-logo">
              <Logo />
            </div>
          </div>

          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li>
                <Link to="/Wallet" className="nk-menu">
                  <strong>
                    Balance :
                    {rewardWalletData &&
                      cctWalletData &&
                      Number(rewardWalletData.balance) +
                        Number(cctWalletData.balance)}
                    &nbsp;CCT
                  </strong>
                </Link>
              </li>

              <li
                className="user-dropdown"
                onClick={() => setVisibility(false)}
              >
                <User />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
