import React, { useState } from "react";
import axios from "axios";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

const API_BASE = "https://backenduser.coincurd.com";

const ApiKeyGenerate = () => {
  const [apiKey, setApiKey] = useState("");
  const [backendError, setBackendError] = useState(null);
  const [planBackendError, setPlanBackendError] = useState(null);
  const [isKeyGenerated, setIsKeyGenerated] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);

  const generateKey = async () => {
    try {
      const response = await axios({
        method: "post",
        url: API_BASE + "/api/add/apiKey",
      });

      let result = response.data;

      if (response && result) {
        setApiKey(result.data.Api_Key);
        setIsKeyGenerated(true);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  const handlePlanSelect = async (plan, price) => {
    try {
      const response = await axios.post(
        API_BASE + "/api/generate/paid/apikey",
        {
          plan: plan,
          price: price,
        }
      );

      if (response.data) {
        setMessage(response.data.message);
        setSelectedPlan(plan);
        setSelectedPrice(price);
      }
    } catch (error) {
      if (error.response) {
        setPlanBackendError(error.response.data.message);
      } else {
        setPlanBackendError("Please try again later!");
      }
    }
  };

  return (
    <React.Fragment>
      <Head title="API Key" />
      <Content>
        <div className="nk-block nk-block-lg">
          <div className="nk-block-head">
            <div className="nk-block-between g-3">
              <div className="nk-block-content">
                <h3 className="nk-block-title title"> Api Key</h3>
                <div className="nk-block-des text-soft">
                  <p>
                    Choose your pricing plan and start enjoying our service.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-gs">
            <div className="col-md-3 col-xxl-3">
              <div className="card-bordered text-center card p-4">
                <div className="w-220px mx-auto">
                  <h5 className="title">Free</h5>
                  <span className="sub-text">
                    If you just want to checkout our API, please click on this
                    plan
                  </span>
                </div>
                <div className="pricing-amount">
                  <div className="amount">$0</div>
                  <span className="bill">100 Request, Allowed</span>
                </div>
                <div className="pricing-action">
                  {!isKeyGenerated && (
                    <div className="full-width">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={generateKey}
                      >
                        Generate API Key
                      </button>
                    </div>
                  )}
                  {isKeyGenerated && (
                    <div className="text-center">
                      <span
                        style={{ fontSize: "13px" }}
                        className="text-cyan fw-bold"
                      >
                        {" "}
                        {apiKey}
                      </span>

                      <div className="mt-2 text-blue text-center">
                        Save it for longer!
                      </div>
                    </div>
                  )}
                  {backendError && (
                    <div className="full-width mt-2">
                      <span className="error text-danger fw-bold">
                        {backendError}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Rendering code for the Starter plan */}
            <div className="col-md-3 col-xxl-3">
              <div className="card-bordered text-center card p-4">
                <div className="w-220px mx-auto">
                  <h5 className="title">Starter</h5>
                  <span className="sub-text">
                    If you are a small business, please select this plan
                  </span>
                </div>
                <div className="pricing-amount">
                  <div className="amount">
                    $99 <span>/yr</span>
                  </div>
                  <span className="bill">1 User, Billed Yearly</span>
                </div>
                <div className="pricing-action">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePlanSelect("Starter", 99)}
                  >
                    Select Plan
                  </button>
                </div>
                {selectedPlan === "Starter" &&
                  selectedPrice === 99 &&
                  message && (
                    <div className="full-width mt-2">
                      <span className="text-success fw-bold">{message}</span>
                    </div>
                  )}
              </div>
            </div>

            {/* Rendering code for the Pro plan */}
            <div className="col-md-3 col-xxl-3">
              <div className="card-bordered text-center recommend card p-4">
                <span className="pricing-badge badge bg-primary text-light">
                  Recommend
                </span>
                <div className="w-220px mx-auto">
                  <h5 className="title">Pro</h5>
                  <span className="sub-text">
                    If you are a small business, please select this plan
                  </span>
                </div>
                <div className="pricing-amount">
                  <div className="amount">
                    $299 <span>/yr</span>
                  </div>
                  <span className="bill">5 User, Billed Yearly</span>
                </div>
                <div className="pricing-action">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePlanSelect("Pro", 299)}
                  >
                    Select Plan
                  </button>
                </div>
                {selectedPlan === "Pro" && selectedPrice === 299 && message && (
                  <div className="full-width mt-2">
                    <span className="text-success fw-bold">{message}</span>
                  </div>
                )}
              </div>
            </div>

            {/* Rendering code for the Enterprise plan */}
            <div className="col-md-3 col-xxl-3">
              <div className="card-bordered text-center card p-4">
                <div className="w-220px mx-auto">
                  <h5 className="title">Enterprise</h5>
                  <span className="sub-text">
                    If you are a small business, please select this plan
                  </span>
                </div>
                <div className="pricing-amount">
                  <div className="amount">
                    $599 <span>/yr</span>
                  </div>
                  <span className="bill">20 User, Billed Yearly</span>
                </div>
                <div className="pricing-action">
                  <button
                    className="btn btn-primary"
                    onClick={() => handlePlanSelect("Enterprise", 599)}
                  >
                    Select Plan
                  </button>
                </div>
                {selectedPlan === "Enterprise" &&
                  selectedPrice === 599 &&
                  message && (
                    <div className="full-width mt-2">
                      <span className="text-success fw-bold">{message}</span>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Content>
    </React.Fragment>
  );
};

export default ApiKeyGenerate;
