import React from "react";

import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";
import { Link } from "react-router-dom";

const Logo = () => {
  return (
    <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
      <img
        className="logo-light logo-img"
        src={coincurdLight}
        alt="logo"
        style={{ width: "100px" }}
      />
      <img
        className="logo-dark logo-img"
        src={coincurdDark}
        alt="logo"
        style={{ width: "100px" }}
      />
    </Link>
  );
};

export default Logo;
