import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";

import {
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  PreviewCard,
} from "../../../components/Component";

function ApiDocumentation() {
  return (
    <React.Fragment>
      <Head title="Developer" />
      <Content>
        <BlockHead>
          <BlockHeadContent>
            <BlockTitle tag="h4">API Documentation</BlockTitle>
            <BlockTitle tag="h7">
              Welcome to our API documentation page!
            </BlockTitle>
          </BlockHeadContent>
        </BlockHead>

        <PreviewCard>
          <div>
            <div>
              <BlockTitle tag="h6">Getting Started</BlockTitle>
              <p>
                If you're new to our API, we recommend starting with the
                following steps:
              </p>
              <ul style={{ listStyleType: "square" }}>
                <li>Create an account and obtain an API key.</li>
                <li>Review the authentication process.</li>
                <li>
                  Explore the available endpoints and their functionalities.
                </li>
                <li>Make your first API request.</li>
              </ul>
            </div>

            <div>
              <BlockTitle tag="h6" className="mt-3">
                Endpoints
              </BlockTitle>
              <p>
                Our Free API provides 100 requests with the following endpoints:
              </p>
              <ul style={{ listStyleType: "circle" }}>
                <li>
                  <a
                    href="https://documenter.getpostman.com/view/27887113/2s93sc5Cmb#67c2e221-8d5c-494f-a660-87bcff2779a7"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                  >
                    /coins
                  </a>
                  : Retrieve coins information!
                </li>
                <li>
                  <a
                    href="https://documenter.getpostman.com/view/27887113/2s93sc5Cmb#780ca7dd-e405-4236-a1d9-5293d046a0cf"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                  >
                    /historical
                  </a>
                  : Retrieve historical information of coins!
                </li>
                <li>
                  <a
                    href="https://documenter.getpostman.com/view/27887113/2s93sc5Cmb#ed3028f3-e6cc-4f7b-853c-c0bee0c055fa"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                  >
                    /exchanges
                  </a>
                  : Retrieve exchange information!
                </li>
              </ul>
            </div>

            <div>
              <BlockTitle tag="h6" className="mt-3">
                Authentication
              </BlockTitle>
              <p>
                Our API requires authentication for most endpoints. You can
                authenticate using an API key.
              </p>
              <p>
                For detailed information about each endpoint, please refer to
                our&nbsp;
                <a
                  href="https://documenter.getpostman.com/view/27887113/2s93sc5Cmb"
                  target="_blank"
                  rel="noreferrer"
                  className="text-link"
                >
                  API documentation
                </a>
                .
              </p>
            </div>
          </div>
        </PreviewCard>
      </Content>
    </React.Fragment>
  );
}

export default ApiDocumentation;
