/* eslint-disable no-unused-vars */
import React, { useState, Fragment } from "react";
import Success from "./Success";
import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";

import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  PreviewCard,
} from "../../components/Component";
import { FormGroup, Form } from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
 
const API_BASE = "https://backenduser.coincurd.com";

const ForgotPassword = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailId, setEmailId] = useState("");

  const [message, setMessage] = useState("");

 

  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(API_BASE + `/api/resetPassword`, {
        emailId,
      });
      setMessage(response.data.message);
      setIsSubmitted(true);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  // if (isSubmitted) {
  //   return (

  //     <Fragment>
  //       <div>
  //         <div className="text-center">
  //           <Form>
  //             <br />
  //             <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
  //               <img
  //                 className="logo-light logo-img logo-img-lg"
  //                 src={coincurdLight}
  //                 alt="logo"
  //               />
  //               <img
  //                 className="logo-dark logo-img logo-img-lg"
  //                 src={coincurdDark}
  //                 alt="logo-dark"
  //               />
  //             </Link>
  //             <br /> <br />
  //             <h4>
  //               Email submitted successfully, <br /> Check your Email!
  //             </h4>
  //           </Form>
  //         </div>
  //       </div>
  //     </Fragment>
  //   );
  // }

  if (isSubmitted) {
    return (
      <React.Fragment>
        <Head title="Success" />
        <PageContainer>
          <div className="nk-content">
            <div className="nk-block nk-block-middle nk-auth-body">
              <div className="brand-logo pb-5">
                <a className="logo-link" href="/">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={coincurdLight}
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={coincurdDark}
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="nk-block-head">
                <div className="nk-block-content">
                  <h4 className="nk-block-title title">
                    Thank you for submitting form
                  </h4>
                  <div className="nk-block-des text-success">
                    <p>Email submitted successfully,Check your Email !</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AuthFooter />
        </PageContainer>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Head title="Forgot-Password" />
      <PageContainer>
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-4 text-center">
            <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
              <img
                className="logo-light logo-img logo-img-lg"
                src={coincurdLight}
                alt="logo"
              />
              <img
                className="logo-dark logo-img logo-img-lg"
                src={coincurdDark}
                alt="logo-dark"
              />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyclassName="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h5">Reset password</BlockTitle>
                <BlockDes>
                  <p>
                    If you forgot or want to change your password, we’ll email
                    you instructions to reset your password.
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            <form onSubmit={handleResetPassword}>
              <FormGroup>
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Email
                  </label>
                </div>
                <input
                  className="form-control form-control-lg"
                  type="email"
                  value={emailId}
                  onChange={(e) => setEmailId(e.target.value)}
                  required
                  placeholder="Enter your email address"
                />
              </FormGroup>
              <FormGroup>
                <Button
                  color="primary"
                  size="lg"
                  className="btn-block"
                  type="submit"
                // onClick={(ev) => ev.preventDefault()}
                >
                  Send Reset Link
                </Button>
              </FormGroup>
              {message && <p className="text-danger">{message}</p>}
              <p>
                Already have an password?&nbsp;
                <Link className="ms-2" to="/auth-login">
                  log In
                </Link>
              </p>
            </form>
          </PreviewCard>
        </Block>
        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default ForgotPassword;
