/* eslint-disable no-unused-vars */
import { atom, selector } from "recoil";
import axios from "axios";

const API_BASE = "https://backenduser.coincurd.com";

let userId = localStorage.getItem("userId");

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

export const userState = atom({
  key: "userState",
  default: null,
});

export const rewardWalletState = atom({
  key: "rewardWalletState",
  default: selector({
    key: "rewardWalletState/default",
    get: async () => {
      // Fetch the default amount from the database or any other source
      const response = await axios({
        method: "get",
        url: API_BASE + `/api/wallet/getUserWalllet/${userId}/1`,
        withCredentials: true,
      });
      const data = response.data.data;
      return data;
    },
  }),
});

export const cctWalletState = atom({
  key: "cctWalletState",
  default: selector({
    key: "cctWalletState/default",
    get: async () => {
      // Fetch the default amount from the database or any other source
      const response = await axios({
        method: "get",
        url: API_BASE + `/api/wallet/getUserWalllet/${userId}/2`,
        withCredentials: true,
      });
      const data = response.data.data;
      return data;
    },
  }),
});
