let menu = [
  { heading: "Dashboard" },
  {
    icon: "home-alt",
    text: "Homepage",
    link: "/",
  },

  // {
  //   icon: "home-alt",
  //   text: "NewPage",
  //   link: "/newPage",
  // },

  // {
  //   icon: "home-alt",
  //   text: "NewDashboard",
  //   link: "/newDashboard",
  // },

  {
    icon: "list-index-fill",
    text: "List Services",
    active: false,
    subMenu: [
      {
        icon: "coin-alt",
        text: "List Currency",
        link: "/List/Currency",
      },
      {
        icon: "coin",
        text: "List Exchange",
        link: "/List/Exchange",
      },
    ],
  },

  {
    icon: "code",
    text: "Developer",
    subMenu: [
      {
        icon: "book-read",
        text: "Documentation",
        link: "/api/documentation",
      },
      {
        icon: "money",
        text: "Api",
        link: "/generate/apikey",
      },
      {
        icon: "money",
        text: "Generated Api",
        link: "/all/api",
      },
    ],
  },
  {
    icon: "upload",
    text: "Marketing",
    subMenu: [
      {
        icon: "upload-cloud",
        text: "Upload Ads",
        link: "/Upload/Ad",
      },
    ],
  },
  {
    icon: "help",
    text: "Support",
    active: false,
    subMenu: [
      {
        icon: "ticket",
        text: "Ticket",
        link: "/ticket",
      },
    ],
  },
];

export default menu;
