import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { UserContextProvider } from "../pages/pre-built/user-manage/UserContext";
import { RedirectAs404 } from "../utils/Utils";

import Blank from "../pages/others/Blank";
import Faq from "../pages/others/Faq";
import Terms from "../pages/others/Terms";

// User Info
import UserListRegularPage from "../pages/pre-built/user-manage/UserListRegular";
import UserDetailsPage from "../pages/pre-built/user-manage/UserDetailsRegular";
import UserListCompact from "../pages/pre-built/user-manage/UserListCompact";
import UserProfileLayout from "../pages/pre-built/user-manage/UserProfileLayout";

// Dashboard
import Homepage from "../pages/userPanel/homepage";

// Support
import ChatSupport from "../pages/userPanel/ticket";

// Marketing
import UploadAdsImages from "../pages/userPanel/marketing/imgAds";

// List Services
import NewAsset from "../pages/userPanel/List_Services/assestListing";
import NewExchange from "../pages/userPanel/List_Services/exchangeListing";

// Wallet
import WalletDetails from "../pages/pre-built/wallet-section/walletTable";
import WalletDashboard from "../pages/pre-built/wallet-section/walletDashboard";

// Developer Section
import ApiKeyGenerate from "../pages/userPanel/developer-section/apiKey";
import ApiListTable from "../pages/userPanel/developer-section/apiListTable";
import ApiDocumentation from "../pages/userPanel/developer-section/documentation";

const Pages = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <Suspense fallback={<div />}>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-list-regular`}
          render={() => (
            <UserContextProvider>
              <UserListRegularPage />
            </UserContextProvider>
          )}
        ></Route>

        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-list-compact`}
          render={() => (
            <UserContextProvider>
              <UserListCompact />
            </UserContextProvider>
          )}
        ></Route>

        <Route //Context Api added
          exact
          path={`${process.env.PUBLIC_URL}/user-details-regular/:id`}
          render={(props) => (
            <UserContextProvider>
              <UserDetailsPage {...props} />
            </UserContextProvider>
          )}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile/`}
          component={UserProfileLayout}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-notification/`}
          component={UserProfileLayout}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-activity/`}
          component={UserProfileLayout}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/user-profile-setting/`}
          component={UserProfileLayout}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/`}
          component={Homepage}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/ticket`}
          component={ChatSupport}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/api/documentation`}
          component={ApiDocumentation}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/generate/apikey`}
          component={ApiKeyGenerate}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/all/api`}
          component={ApiListTable}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Upload/Ad`}
          component={UploadAdsImages}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/List/Currency`}
          component={NewAsset}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/List/Exchange`}
          component={NewExchange}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/Wallet`}
          component={WalletDashboard}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/allReferral`}
          component={WalletDetails}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/_blank`}
          component={Blank}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/terms-policy`}
          component={Terms}
        ></Route>

        <Route
          exact
          path={`${process.env.PUBLIC_URL}/pages/faq`}
          component={Faq}
        ></Route>

        <Route component={RedirectAs404}></Route>
      </Switch>
    </Suspense>
  );
};
export default Pages;
