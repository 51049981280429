import React, { useState } from "react";
import { FaPlus, FaTrash } from "react-icons/fa";
import axios from "axios";
import { useForm } from "react-hook-form";
import { FormGroup, Label, Col, Row, Container, Form } from "reactstrap";

import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";

import { BlockTitle, PreviewCard } from "../../../components/Component";

const API_BASE = "https://backenduser.coincurd.com";

const predefinedCategories = [
  "Category",
  "Industry",
  "Platform",
  "Self-Reported Tags",
];

const NewAsset = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [backendError, setBackendError] = useState(null);

  const [whitePaperLink, setWhitePaperLink] = useState([{ link: "" }]);
  const [gitHubLinks, setGitHubLinks] = useState([{ link: "" }]);
  const [tags, setTags] = useState([{ category: "", values: [""] }]);
  const [contractAddresses, setContractAddresses] = useState([
    { Network: "", Address: "" },
  ]);
  const [chainExplorers, setChainExplorers] = useState([{ link: "" }]);
  const [supportedWallets, setSupportedWallets] = useState([
    { walletName: "", link: "" },
  ]);
  const [officialLinks, setOfficialLinks] = useState([{ link: "" }]);

  const handleAddLink = (setState, initialState) => {
    setState([...initialState, { link: "" }]);
  };

  const handleRemoveLink = (index, setState, initialState) => {
    const newLinks = [...initialState];
    newLinks.splice(index, 1);
    setState(newLinks);
  };

  const isCategorySelected = (category, currentIndex) => {
    return tags.some(
      (tag, index) => tag.category === category && index !== currentIndex
    );
  };

  const handleCategoryChange = (index, category) => {
    const newTags = [...tags];
    newTags[index].category = category;
    newTags[index].values = [""];
    setTags(newTags);
  };

  const handleTagValueChange = (index, valueIndex, value) => {
    const newTags = [...tags];
    newTags[index].values[valueIndex] = value;
    setTags(newTags);
  };

  const handleAddFieldValue = (index) => {
    const newTags = [...tags];
    newTags[index].values.push("");
    setTags(newTags);
  };

  const handleRemoveFieldValue = (index, valueIndex) => {
    const newTags = [...tags];
    newTags[index].values.splice(valueIndex, 1);
    setTags(newTags);
  };

  axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onFormSubmit = async (e) => {
    try {
      const response = await axios({
        method: "post",
        url: API_BASE + "/api/add/coin",
        data: {
          ...e,
          whitePaperLink: whitePaperLink.map((linkObj) => linkObj.link),
          gitHubLink: gitHubLinks.map((linkObj) => linkObj.link),
          tags: tags.map((tagObj) => ({
            category: tagObj.category,
            values: tagObj.values.filter((value) => value.trim() !== ""),
          })),
          contractAddress: contractAddresses,
          chainExlorers: chainExplorers.map((linkObj) => linkObj.link),
          supportedWallet: supportedWallets,
          officialLinks: officialLinks.map((linkObj) => linkObj.link),
        },
      });

      if (response) {
        setIsSubmitted(true);
      }
    } catch (error) {
      if (error.response) {
        setBackendError(error.response.data.message);
      } else {
        setBackendError("Please try again later!");
      }
    }
  };

  const handleRemoveCategory = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  };

  if (isSubmitted) {
    return (
      <React.Fragment>
        <Content>
          <Container>
            <Row>
              <div className="full-width" style={{ textAlign: "center" }}>
                <h3>Asset Details submitted successfully!</h3>
                <a href="/" className="mb-3 btn btn-primary">
                  HomePage
                </a>
              </div>
            </Row>
          </Container>
        </Content>
      </React.Fragment>
    );
  }

  return (
    <div className="pt-5">
      <Head title="Add Currency" />

      <PreviewCard className="pt-4">
        <BlockTitle tag="h5">List Currency</BlockTitle>
        <Form onSubmit={handleSubmit(onFormSubmit)} className="pt-2">
          <Row className="gy-4">
            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Coin/Token Name
                </Label>
                <div className="form-control-wrap">
                  <input
                    type="text"
                    id="default-0"
                    placeholder="Coin/Token Name"
                    className="form-control"
                    {...register("coinName", {
                      required: "This field is required",
                    })}
                  />
                  {errors.coinName && (
                    <span className="error text-danger">
                      {errors.coinName.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Symbol
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="default-0"
                    placeholder="Symbol"
                    {...register("symbol", {
                      required: "This field is required",
                    })}
                  />
                  {errors.symbol && (
                    <span className="error text-danger">
                      {errors.symbol.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-1" className="form-label">
                  Issue Price
                </Label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Usd</span>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    id="default-1"
                    placeholder="Issue Price"
                    {...register("issuePrice", {
                      required: "This field is required",
                    })}
                  />
                  {errors.issuePrice && (
                    <span className="error text-danger">
                      {errors.issuePrice.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Issue Date
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="date"
                    id="default-0"
                    placeholder="Issue Date"
                    {...register("issueDate", {
                      required: "This field is required",
                    })}
                  />
                  {errors.issueDate && (
                    <span className="error text-danger">
                      {errors.issueDate.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Max Supply
                </Label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Usd</span>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    id="default-0"
                    placeholder="Max Supply"
                    {...register("maxSupply", {
                      required: "This field is required",
                    })}
                  />
                  {errors.maxSupply && (
                    <span className="error text-danger">
                      {errors.maxSupply.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Circulating Supply
                </Label>
                <div className="form-control-wrap">
                  <div className="form-text-hint">
                    <span className="overline-title">Usd</span>
                  </div>
                  <input
                    className="form-control"
                    type="number"
                    id="default-0"
                    placeholder="Circulating Supply"
                    {...register("circulatingSupply", {
                      required: "This field is required",
                    })}
                  />
                  {errors.circulatingSupply && (
                    <span className="error text-danger">
                      {errors.circulatingSupply.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Logo Link
                </Label>
                <div className="form-control-wrap">
                  <input
                    className="form-control"
                    type="text"
                    id="default-0"
                    placeholder=" Logo Link"
                    {...register("logoLink", {
                      required: "This field is required",
                    })}
                  />
                  {errors.logoLink && (
                    <span className="error text-danger">
                      {errors.logoLink.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>

            <Col sm="3">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  White Paper
                </Label>
                <div className="form-control-wrap">
                  {whitePaperLink.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-12 mb-1">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="white Paper Link"
                            value={linkObj.link}
                            onChange={(e) => {
                              const newLinks = [...whitePaperLink];
                              newLinks[index].link = e.target.value;
                              setWhitePaperLink(newLinks);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setWhitePaperLink,
                                    whitePaperLink
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.whitePaperLink?.[index] && (
                          <span className="error text-danger">
                            {errors.whitePaperLink[index].message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setWhitePaperLink, whitePaperLink)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Supported Wallets
                </Label>
                <div className="form-control-wrap">
                  {supportedWallets.map((wallet, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-5">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Wallet Name"
                            value={wallet.walletName}
                            onChange={(e) => {
                              const newWallets = [...supportedWallets];
                              newWallets[index].walletName = e.target.value;
                              setSupportedWallets(newWallets);
                            }}
                          />
                        </div>
                        {errors.supportedWallets?.[index]?.walletName && (
                          <span className="error text-danger">
                            {errors.supportedWallets[index].walletName.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-7">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Wallet Link"
                            value={wallet.link}
                            onChange={(e) => {
                              const newWallets = [...supportedWallets];
                              newWallets[index].link = e.target.value;
                              setSupportedWallets(newWallets);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setSupportedWallets,
                                    supportedWallets
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.supportedWallets?.[index]?.link && (
                          <span className="error text-danger">
                            {errors.supportedWallets[index].link.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setSupportedWallets, supportedWallets)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Contract Addresses
                </Label>
                <div className="form-control-wrap">
                  {contractAddresses.map((contract, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-5">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Network"
                            value={contract.Network}
                            onChange={(e) => {
                              const newContracts = [...contractAddresses];
                              newContracts[index].Network = e.target.value;
                              setContractAddresses(newContracts);
                            }}
                          />
                        </div>
                        {errors.contractAddresses?.[index]?.Network && (
                          <span className="error text-danger">
                            {errors.contractAddresses[index].Network.message}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-md-7">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Address"
                            value={contract.Address}
                            onChange={(e) => {
                              const newContracts = [...contractAddresses];
                              newContracts[index].Address = e.target.value;
                              setContractAddresses(newContracts);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setContractAddresses,
                                    contractAddresses
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.contractAddresses?.[index]?.Address && (
                          <span className="error text-danger">
                            {errors.contractAddresses[index].Address.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setContractAddresses, contractAddresses)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  GitHub
                </Label>
                <div className="form-control-wrap">
                  {gitHubLinks.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-12 mb-1">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="GitHub Link"
                            value={linkObj.link}
                            onChange={(e) => {
                              const newLinks = [...gitHubLinks];
                              newLinks[index].link = e.target.value;
                              setGitHubLinks(newLinks);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setGitHubLinks,
                                    gitHubLinks
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.gitHubLink?.[index] && (
                          <span className="error text-danger">
                            {errors.gitHubLink[index].message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() => handleAddLink(setGitHubLinks, gitHubLinks)}
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>

            {/* <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Tags
                </Label>
                <div className="form-control-wrap">
                  {tags.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-10">
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Tags"
                          value={linkObj.tag}
                          onChange={(e) => {
                            const newLinks = [...tags];
                            newLinks[index].tag = e.target.value;
                            setTags(newLinks);
                          }}
                        />
                        {errors.tags?.[index] && (
                          <span className="error text-danger">
                            {errors.tags[index].message}
                          </span>
                        )}
                      </div>
                      {index > 0 && (
                        <div className="form-group col-md-2">
                          <span
                            className="remove-icon"
                            onClick={() =>
                              handleRemoveLink(index, setTags, tags)
                            }
                          >
                            <FaTrash />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() => handleAddLink(setTags, tags)}
                  >
                    <FaPlus /> Add Tags
                  </div>
                </div>
              </FormGroup>
            </Col> */}

            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Chain Exlorers
                </Label>
                <div className="form-control-wrap">
                  {chainExplorers.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-12 mb-1">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Chain Exlorer Link"
                            value={linkObj.link}
                            onChange={(e) => {
                              const newLinks = [...chainExplorers];
                              newLinks[index].link = e.target.value;
                              setChainExplorers(newLinks);
                            }}
                          />

                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setChainExplorers,
                                    chainExplorers
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.chainExplorers?.[index] && (
                          <span className="error text-danger">
                            {errors.chainExplorers[index].message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setChainExplorers, chainExplorers)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Official Links
                </Label>
                <div className="form-control-wrap">
                  {officialLinks.map((linkObj, index) => (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-12 mb-1">
                        <div className="input-group">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Official Link"
                            value={linkObj.link}
                            onChange={(e) => {
                              const newLinks = [...officialLinks];
                              newLinks[index].link = e.target.value;
                              setOfficialLinks(newLinks);
                            }}
                          />
                          {index > 0 && (
                            <div className="input-group-append p-1">
                              <span
                                className="remove-icon"
                                onClick={() =>
                                  handleRemoveLink(
                                    index,
                                    setOfficialLinks,
                                    officialLinks
                                  )
                                }
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                        </div>
                        {errors.officialLinks?.[index] && (
                          <span className="error text-danger">
                            {errors.officialLinks[index].message}
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() =>
                      handleAddLink(setOfficialLinks, officialLinks)
                    }
                  >
                    <FaPlus /> Add More
                  </div>
                </div>
              </FormGroup>
            </Col>
            {/* <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Tags
                </Label>
                <div className="form-control-wrap">
                  {tags.map((tagObj, index) => (
                    <div key={index} className="row">
                      <div className="col-md-6">
                        <select
                          className="form-control"
                          value={tagObj.category}
                          onChange={(e) =>
                            handleCategoryChange(index, e.target.value)
                          }
                        >
                          <option value="">Select a category</option>
                          {predefinedCategories.map((category) => (
                            <option
                              key={category}
                              value={category}
                              disabled={isCategorySelected(category)}
                            >
                              {category}
                            </option>
                          ))}
                        </select>
                        {index > 0 && (
                          <span
                            className="remove-icon"
                            onClick={() => handleRemoveCategory(index)}
                          >
                            <FaTrash />
                          </span>
                        )}
                      </div>
                      {tagObj.category && (
                        <div className="col-md-6">
                          <div>
                            {tagObj.values.map((value, valueIndex) => (
                              <div key={valueIndex}>
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Tag Value"
                                  value={value}
                                  onChange={(e) => {
                                    const newTags = [...tags];
                                    newTags[index].values[valueIndex] =
                                      e.target.value;
                                    setTags(newTags);
                                  }}
                                />
                                {valueIndex > 0 && (
                                  <span
                                    className="remove-icon"
                                    onClick={() =>
                                      handleRemoveFieldValue(index, valueIndex)
                                    }
                                  >
                                    <FaTrash />
                                  </span>
                                )}
                              </div>
                            ))}
                            <div
                              className="add-icon"
                              onClick={() => handleAddFieldValue(index)}
                            >
                              <FaPlus /> Add Tag Value
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() => handleAddLink(setTags, tags)}
                  >
                    <FaPlus /> Add Tags
                  </div>
                </div>
              </FormGroup>
            </Col> */}

            <Col sm="4">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Tags
                </Label>
                <div className="form-control-wrap">
                  {tags.map((tagObj, index) => (
                    <div key={index} className="row">
                      <div className="col-md-6 mb-1">
                        <div className="input-group">
                          {index > 0 && (
                            <div className="input-group-append m-1">
                              <span
                                className="remove-icon"
                                onClick={() => handleRemoveCategory(index)}
                              >
                                <FaTrash />
                              </span>
                            </div>
                          )}
                          <select
                            className="form-control"
                            value={tagObj.category}
                            onChange={(e) =>
                              handleCategoryChange(index, e.target.value)
                            }
                          >
                            <option value="">Select a category</option>
                            {predefinedCategories.map((category) => (
                              <option
                                key={category}
                                value={category}
                                disabled={isCategorySelected(category, index)}
                              >
                                {category}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      {tagObj.category && (
                        <div className="col-md-6">
                          <div>
                            {tagObj.values.map((value, valueIndex) => (
                              <div
                                key={valueIndex}
                                className="input-group mb-1"
                              >
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Tag Value"
                                  value={value}
                                  onChange={(e) =>
                                    handleTagValueChange(
                                      index,
                                      valueIndex,
                                      e.target.value
                                    )
                                  }
                                />
                                {valueIndex > 0 && (
                                  <div className="input-group-append p-1">
                                    <span
                                      className="remove-icon"
                                      onClick={() =>
                                        handleRemoveFieldValue(
                                          index,
                                          valueIndex
                                        )
                                      }
                                    >
                                      <FaTrash />
                                    </span>
                                  </div>
                                )}
                              </div>
                            ))}
                            <div
                              className="add-icon"
                              onClick={() => handleAddFieldValue(index)}
                            >
                              <FaPlus /> Add More
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  <div
                    className="add-icon"
                    onClick={() => handleAddLink(setTags, tags)}
                  >
                    <FaPlus /> Add Tags
                  </div>
                </div>
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup>
                <Label htmlFor="default-0" className="form-label">
                  Social Links
                </Label>
                <div className="form-control-wrap">
                  <div className="form-row">
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="LinkedIn"
                        placeholder="LinkedIn"
                        {...register("socialLinks.LinkedIn")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="facebook"
                        placeholder="Facebook"
                        {...register("socialLinks.Facebook")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="twitter"
                        placeholder="Twitter"
                        {...register("socialLinks.Twitter")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="Instagram"
                        placeholder="Instagram"
                        {...register("socialLinks.Instagram")}
                      />
                    </div>
                  </div>
                  <div className="form-row pt-1">
                    {/* Add the input fields for the next three social media platforms here */}
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="Pinterest"
                        placeholder="Pinterest"
                        {...register("socialLinks.Pinterest")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="telegram"
                        placeholder="Telegram"
                        {...register("socialLinks.Telegram")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="discord"
                        placeholder="Discord"
                        {...register("socialLinks.Discord")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="reddit"
                        placeholder="Reddit"
                        {...register("socialLinks.Reddit")}
                      />
                    </div>
                  </div>
                  <div className="form-row pt-1">
                    {/* Add the input fields for the next three social media platforms here */}

                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="message_Board"
                        placeholder="Message_Board"
                        {...register("socialLinks.Message_Board")}
                      />
                    </div>

                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="Messenger"
                        placeholder="Messenger"
                        {...register("socialLinks.Messenger")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="YouTube"
                        placeholder="YouTube"
                        {...register("socialLinks.YouTube")}
                      />
                    </div>
                    <div className="form-group col-md-3">
                      <input
                        className="form-control"
                        type="text"
                        id="Quora"
                        placeholder="Quora"
                        {...register("socialLinks.Quora")}
                      />
                    </div>
                  </div>
                  <div className="form-row pt-1">
                    {/* Add the input fields for the next three social media platforms here */}
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="TikTok"
                        placeholder="TikTok"
                        {...register("socialLinks.TikTok")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="Tumblr"
                        placeholder="Tumblr"
                        {...register("socialLinks.Tumblr")}
                      />
                    </div>
                    <div className="form-group col-md-4">
                      <input
                        className="form-control"
                        type="text"
                        id="Chat"
                        placeholder="Chat"
                        {...register("socialLinks.Chat")}
                      />
                    </div>
                  </div>
                </div>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <Label htmlFor="default-textarea" className="form-label">
                  Coin/Token Description
                </Label>
                <div className="form-control-wrap">
                  <textarea
                    className="no-resize form-control"
                    type="textarea"
                    id="default-textarea"
                    //   defaultValue="Give the description of token..."
                    placeholder="Give the description of token..."
                    {...register("coinDescription", {
                      required: "This field is required",
                    })}
                  />
                  {errors.coinDescription && (
                    <span className="error text-danger">
                      {errors.coinDescription.message}
                    </span>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup>
                <div>
                  <button type="submit" className="mb-3 btn btn-primary">
                    Submit
                  </button>
                </div>
              </FormGroup>
            </Col>
          </Row>
        </Form>
        {backendError && (
          <div className="full-width mt-2">
            <span className="error text-danger fw-bold">{backendError}</span>
          </div>
        )}
      </PreviewCard>
    </div>
  );
};

export default NewAsset;
