import React from "react";
import coincurdLight from "../../../src/assets/images/coincurdLight.png";
import coincurdDark from "../../../src/assets/images/coincurdDark.png";
import PageContainer from "../../layout/page-container/PageContainer";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  Button,
  BlockTitle,
} from "../../components/Component";
// import { Link } from "react-router-dom";
// import { Button } from "reactstrap";

const Success = () => {
  return (
    <React.Fragment>
      <Head title="Success" />
      <PageContainer>
        <div className="nk-content">
          <div className="nk-block nk-block-middle nk-auth-body">
            <div className="brand-logo pb-5">
              <a className="logo-link" href="/demo8/">
                <img
                  className="logo-light logo-img logo-img-lg"
                  src={coincurdLight}
                  alt="logo"
                />
                <img
                  className="logo-dark logo-img logo-img-lg"
                  src={coincurdDark}
                  alt="logo-dark"
                />
              </a>
            </div>
            <div className="nk-block-head">
              <div className="nk-block-content">
                <h4 className="nk-block-title title">
                  Thank you for submitting form
                </h4>
                <div className="nk-block-des text-success">
                  <p>You can now sign in with your new password</p>
                  <a href="/auth-login">
                    <Button
                      color="primary"
                      size="lg"
                      className="btn-block"
                      type="submit"
                    >
                      Back to Login
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AuthFooter />
      </PageContainer>
    </React.Fragment>
  );
};
export default Success;
